import * as React from "react";
import { PageLayout } from "./PageLayout";
import styles from "../App.module.css";
import { useState, useEffect } from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Prism from "prismjs";
import "prismjs/themes/prism.css";

export function ChatgptPage() {
    const [result, setResult] = useState("");
    const [mathpixResult, setMathpixResult] = useState();
    const [originalMathpixResult, setOriginalMathpixResult] = useState();
    const [base64string, setBase64String] = useState("");
    const [loading, setLoading] = useState(false);
    const [designCheckTemplate, setDesignCheckTemplate] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [originalTemplate, setOriginalTemplate] = useState("");
    const [selectedModel, setSelectedModel] = useState("gpt3");

    useEffect(() => {
        fetch("/DesignCheckExample.md")
            .then((response) => response.text())
            .then((data) => {
                setDesignCheckTemplate(data);
                setOriginalTemplate(data);
            })
            .catch((error) => {
                console.error("Error reading file:", error);
            });
    }, []);

    useEffect(() => {
        Prism.highlightAll();
    }, [result]); // re-run when `result` changes

    function handleEditClick() {
        setIsEditing(true);
    }

    function handleBackClick() {
        setIsEditing(false);
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setBase64String(reader.result);
                resolve(reader.result); // resolve with the base64 string
            };
            reader.onerror = function (error) {
                console.log("error:", error);
                reject(error);
            };
        });
    }

    // Submit to Mathpix
    async function fileUpload(base64data) {
        try {
            const response = await fetch(
                "https://chatgpt-designcheck.azurewebsites.net/mathpix",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        img: base64data,
                    }),
                }
            );

            const data = await response.json();

            const mathpixResultText = data.text;
            setMathpixResult(mathpixResultText);
            setOriginalMathpixResult(mathpixResultText);
        } catch (error) {}
    }

    async function onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            getBase64(file).then((base64Data) => {
                fileUpload(base64Data);
            });
        }
    }

    async function submitToOpenAI(event) {
        event.preventDefault();
        try {
            setLoading(true);

            // Adjust URL by selected model
            const endpoint = selectedModel === "gpt3" ? "/gpt3" : "/gpt4";
            const url =`https://chatgpt-designcheck.azurewebsites.net/generate${endpoint}`;
            // this url is for future deployment

            //const url = `http://localhost:3000/generate${endpoint}`; // this url is for temporary test
            console.log("url is:", url);

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    message: mathpixResult,
                    template: designCheckTemplate,
                }),
            });
            setLoading(false);
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const data = await response.json();
                setResult(data.result); // Updating the result with OpenAI's response
            } else {
                console.error("Unexpected response:", await response.text());
            }

            //const data = await response.json();
            //setResult(data.result); // Updating the result with OpenAI's response
        } catch (error) {
            setLoading(false);
            console.error("Error submitting to OpenAI:", error);
        }
    }

    function copyCode(result) {
        // Get the text field

        navigator.clipboard.writeText(result);

        // Alert the copied text
        alert("Code copied to clipboard");
    }

    function showLoader() {
        document.getElementById("loader").style.display = "block";
    }

    function hideLoader() {
        document.getElementById("loader").style.display = "none";
    }

    return (
        <div>
            <PageLayout />

            <main className={styles.main}>
                {/* // Use Flexbox to divide this layout into two columns*/}
                <div className={styles.flexLayout}>
                    {/* left side content*/}
                    <div className={styles.leftColumn}>
                        {isEditing ? (
                            <>
                                <div className={styles.editFlexLayout}>
                                    <div className={styles.editTwoButtons}>
                                        <button
                                            className={styles.basicButton}
                                            onClick={() =>
                                                setDesignCheckTemplate(
                                                    originalTemplate
                                                )
                                            }
                                        >
                                            Revert
                                        </button>

                                        <button
                                            className={styles.basicButton}
                                            style={{
                                                width: "15vw",
                                                minWidth: "200px",
                                            }}
                                            onClick={handleBackClick}
                                        >
                                            Return to Main Page
                                        </button>
                                    </div>

                                    <code>
                                        <textarea
                                            className={styles.editPromptCode}
                                            value={designCheckTemplate}
                                            onChange={(e) =>
                                                setDesignCheckTemplate(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </code>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.codeBlockSmall}>
                                    <span className={styles.masterPromptBlock}>
                                        Master Prompt
                                    </span>
                                    <button
                                        className={styles.basicButton}
                                        style={{ marginRight: "45px" }}
                                        onClick={handleEditClick}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <br />

                                <div className={styles.codeBlockSmall}>
                                    <span className={styles.mathpixHelperBlock}>
                                        Mathpix Helper
                                    </span>
                                    <form
                                        onSubmit={fileUpload}
                                        style={{ flexGrow: 1 }}
                                    >
                                        <input
                                            className={styles.fileInput}
                                            type="file"
                                            id="calcImage"
                                            name="calcImage"
                                            accept="image/png, image/jpeg"
                                            onChange={onFileChange}
                                        />

                                        {loading && (
                                            <div className="loader"></div>
                                        )}
                                    </form>

                                    <button
                                        className={styles.basicButton}
                                        style={{ marginRight: "45px" }}
                                        onClick={() =>
                                            document
                                                .getElementById("calcImage")
                                                .click()
                                        }
                                    >
                                        Upload
                                    </button>
                                </div>

                                <br />
                                <div>
                                    <div
                                        className={styles.codeBlock}
                                        style={{
                                            height: "550px",
                                            maxHeight: "600px",
                                        }}
                                    >
                                        <div
                                            className={
                                                styles.mathpixResultBlock
                                            }
                                        >
                                            <span>Mathpix Result</span>

                                            <button
                                                className={styles.basicButton}
                                                style={{
                                                    marginBottom: "8px",
                                                    marginRight: "5px",
                                                }}
                                                onClick={() =>
                                                    setMathpixResult(
                                                        originalMathpixResult
                                                    )
                                                }
                                            >
                                                Revert
                                            </button>
                                        </div>

                                        <MathJaxContext>
                                            <MathJax
                                                className={styles.mathpix}
                                                    id="result"
                                                    dynamic
                                            >
                                                {mathpixResult}
                                            </MathJax>
                                        </MathJaxContext>
                                        <br />
                                        <h5 className={styles.editBlockTitle}>
                                            Mathpix generated text (edit it by
                                            yourself!)
                                        </h5>

                                        <div
                                            className={
                                                styles.editMathpixResultBlock
                                            }
                                        >
                                            <code>
                                                <textarea
                                                    className={
                                                        styles.editMathpixResultText
                                                    }
                                                    value={mathpixResult}
                                                    onChange={(e) =>
                                                        setMathpixResult(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </code>
                                        </div>

                                        <div style={{ textAlign: "left" }}>
                                            <button
                                                className={styles.createButton}
                                                onClick={submitToOpenAI}
                                            >
                                                {" "}
                                                Create{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    {/* right column*/}
                    <div className={styles.rightColumn}>
                        <h2 className={styles.rightColumnTitle}>
                            Design Check Calculation
                        </h2>

                        {/* Model selection button*/}
                        <div className={styles.moduleSelection}>
                            <label style={{ margin: "0 2.5px" }}>
                                <input
                                    type="radio"
                                    value="gpt3"
                                    checked={selectedModel === "gpt3"}
                                    onChange={() => setSelectedModel("gpt3")}
                                />
                                GPT-3
                            </label>
                            <label style={{ margin: "0 2.5px" }}>
                                <input
                                    type="radio"
                                    value="gpt4"
                                    checked={selectedModel === "gpt4"}
                                    onChange={() => setSelectedModel("gpt4")}
                                />
                                GPT-4
                            </label>
                        </div>

                        <div
                            className={styles.codeBlock}
                            style={{ maxHeight: "630px" }}
                        >
                            <div className={styles.codeBlockHeader}>
                                {!loading && result && (
                                    <button
                                        className={styles.copyButton}
                                        onClick={() => {
                                            copyCode(result);
                                        }}
                                    >
                                        Copy code
                                    </button>
                                )}
                            </div>

                            <br />

                            {loading && (
                                <div
                                    className={styles.loader}
                                    id="loader"
                                ></div>
                            )}
                            {!loading && (
                                <pre className={styles.test} id="result">
                                    <code
                                        className="language-javascript"
                                        style={{
                                            color: "black",
                                            maxHeight: "450px",
                                            overflow: "auto",
                                        }}
                                    >
                                        {result}
                                    </code>
                                </pre>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
