import React from "react";
import { useMsal } from "@azure/msal-react";
// import Button from "react-bootstrap/Button";
import Button from '@mui/material/Button';

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        console.log("Logging out using:", logoutType);
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            style={{backgroundColor:"#E61E28"}}
            title="Sign Out"
			onClick={handleLogout}
			>
				Sign Out
        </Button>
    );
};
