import React from "react";
import { PageLayout } from "./PageLayout";
import ReactMarkdown from "react-markdown";

import EditMathpixResult from "../assets/imgs/editmathpixresult.png";
import EditPrompt from "../assets/imgs/editprompt.png";
import EditPromptButton from "../assets/imgs/editpromptbutton.png";
import Login from "../assets/imgs/welcomepage.png";
import MainPage from "../assets/imgs/mainpage.jpeg";
import MathpixResult from "../assets/imgs/mathpixresult.png";
import OpenAiResult from "../assets/imgs/openairesult.png";
import Select from "../assets/imgs/select.png";
import Exp1 from "../assets/imgs/exp1.png";
import Exp2 from "../assets/imgs/exp2.png";
import styles from "../App.module.css";
import remarkGfm from 'remark-gfm'

export default function AboutPage() {
    const header = `
# How to Use
---
This guide explains how to use AI DesignCheck to create your own engineering calculations. 

Sign in using your Arup account.

![Log in](${Login})`;

    const simpleUse = `
## Simple Use
---

You can generate code for contributing new libraries to DesignCheck.

Step 1. Click on the "Upload" button. 

![Upload](${MainPage})



Step 2. Navigate to your local directory and select an image of an engineering equation.

![Select](${Select})

&nbsp;

Step 3. The equation from the image will be converted into LaTex format. Click the "Create" button to send the equation to ChatGPT. 

Please note: It might take a moment to receive the result from ChatGPT.

![Latex](${MathpixResult})


Step 4. Once the code is returned, click the "Copy Code" button to copy it. You can now use the code to create your own engineering calculation.

![OpenAI Result](${OpenAiResult})

*We have provided an example for you to explore. Click on the equation below to download it and give it a try!*

`;

    const advancedUse = `

## Advanced Use
---
If you are looking to customise further and have more control, the advanced features are tailored for you.

`;

    const editMaster = 
`
### Edit the Master Prompt
---

You can also modify the master prompt sent to ChatGPT.

Step 1. Click the "Edit" button. It will take you to the editing page.

![Edit Master Prompt button](${EditPromptButton})

Step 2. Make the changes to the master prompt.

![Edit Master Prompt](${EditPrompt})

If you are unsatisefied with the edits, click the "Revert" button to restore original prompt. After completing your edits, click "Return to Main Page" to go back. 

*Here is another equation. Click on it to download and explore the advanced features!*
`

    const editMathpix =
`
### Edit the Mathpix Result
---

You can manully edit the equation. If you are unsatisfied with your edits, simply click the "Revert" button to restore the original equation.

![Edit Mathpix Result](${EditMathpixResult})
`

    const madeBy = `
# Made by
---
Stamatios Psarras, Giulio Antonutto, Jake Adkin, Clara Wang.

For more information about this project, please visit INVEST IN ARUP website:

	`;

    const gptComparison = `
### GPT-3.5 vs GPT-4
---
You can choose whether your calculation is generated with GPT-3.5 or GPT-4, each come with their own advantages:

| GPT-3.5                                  | GPT-4                                         |
|------------------------------------------|-----------------------------------------------|
| Considerably faster at generating results | Creates more sophisticated and nuanced results |
| Suitable for simpler equations            | Can handle more complex and larger equations   |
|                                          |                                               |
`;

    function CustomImage(props) {
        return (
            <img
                {...props}
                style={{ maxWidth: "100%", width: "600px", height: "auto" }}
            />
        );
    }

    return (
        <PageLayout>

            <div className={styles.container}>
                <div className={styles.navigation}>
                    <h2>Contents</h2>
                    <ul>
                        <li><a href="#how-to-use">How to Use</a>
                            <ul>
                                <li><a href="#simple-use">Simple Use</a></li>
                                <li><a href="#advanced-use">Advanced Use</a></li>
                                <ul>
                                    <li><a href="#edit-mathpix">Edit Mathpix Result</a></li>
                                    <li><a href="#edit-master">Edit Master Prompt</a></li>
                                    <li><a href="#gpt-comparison">GPT-3.5 vs GPT-4</a></li>
                                </ul>
                            </ul>
                        </li>
                        <li><a href="#made-by">Made By</a></li>
                    </ul>
                </div>
                <div className={styles.mainContent}>
                    <div id="how-to-use" className={styles.scrollTarget}>
                        <ReactMarkdown components={{ img: CustomImage }}>
                            {header}
                        </ReactMarkdown>
                        <div id="simple-use" className={styles.scrollTarget}>
                            <ReactMarkdown components={{ img: CustomImage }}>
                                {simpleUse}
                            </ReactMarkdown>
                        </div>
                        <a href={Exp1} download>
                            <img src={Exp1} alt="Example 1" />
                        </a>
                        <br />
                        <br />
                        <div id="advanced-use" className={styles.scrollTarget}>
                            <ReactMarkdown components={{ img: CustomImage }}>
                                {advancedUse}
                            </ReactMarkdown>
                        </div>
                        <div id="edit-mathpix" className={styles.scrollTarget}>
                            <ReactMarkdown components={{ img: CustomImage }}>
                                {editMathpix}
                            </ReactMarkdown>
                        </div>
                        <div id="edit-master" className={styles.scrollTarget}>
                            <ReactMarkdown components={{ img: CustomImage }}>
                                {editMaster}
                            </ReactMarkdown>
                        </div>
                        <a href={Exp2} download>
                            <img src={Exp2} alt="Example 2" width="300" height="auto" />
                        </a>
                        <br />
                        <br />
                        <div id="gpt-comparison">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {gptComparison}
                            </ReactMarkdown>
                        </div>

                    </div>
                    <div id='made-by' className={styles.scrollTarget}>
                        <ReactMarkdown components={{ img: CustomImage }}>
                            {madeBy}
                        </ReactMarkdown>
                    </div>
                    <a href="https://invest.arup.com/?layout=projsheet&projid=36724">
                        AI for Producing Engineering Calcs with DesignCheck
                    </a>
                </div>
            </div>
        </PageLayout>
    );
}
