let config = {
    local_redirectUri: "http://localhost:8573/",
    dev_redirectUri:
        "https://yellow-rock-076662110-3.centralus.3.azurestaticapps.net/",
    prod_redirectUri: "https://yellow-rock-076662110.3.azurestaticapps.net/",
};

let redirectUri = "";

const hostname = window.location.hostname;
if (hostname.includes("localhost")) {
    redirectUri = config.local_redirectUri;
} else if (hostname.includes("centralus")) {
    redirectUri = config.dev_redirectUri;
} else if (hostname.includes("yellow-rock")) {
    redirectUri = config.prod_redirectUri;
}

export { redirectUri };
