import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import AboutPage from "./AboutPage";
import LoginPage from "./LoginPage";
import { ChatgptPage } from "./ChatgptPage";

export const SafeRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export const AllRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/about" element={<AboutPage />} />
                <Route path="/" element={<ChatgptPage />} />
            </Routes>
        </BrowserRouter>
    );
};
