import * as React from "react";
import { SignInButton } from "./SignInButton";

export default function LoginPage() {
    return (
        <div
            style={{
                backgroundColor: "#FFFFFF",
                // color: "#FFFFFF",
                minHeight: "100vh",
                flexDirection: "column",
                textAlign: "center",
                paddingTop: "30vh",
            }}
        >
            <div>
                <h1 style={{ color: "#E61E28" }}> 
                    Welcome to AI DesignCheck!
                </h1>
                <p> Please sign in to continue.</p>
                <SignInButton />
            </div>
        </div>
    );
}
