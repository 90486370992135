import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from '@mui/material/Button';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        // if (loginType === "popup") {
        //     instance.loginPopup(loginRequest).catch((e) => {
        //         console.log(e);
        //     });
        // } else if (loginType === "redirect") {
        //     instance.loginRedirect(loginRequest).catch((e) => {
        //         console.log(e);
        //     });
        // }
		instance.loginRedirect(loginRequest).catch((e)=>{
			console.log(e);
		});
    };
    return (
        <Button
            variant="contained"
            color="primary"
            style={{backgroundColor:"#E61E28"}}
            title="Sign In"
			onClick={handleLogin}
			>
				Sign In
        </Button>
    );
};
