import "./App.css";
import * as React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { AllRoutes, SafeRoutes } from "./components/AppRoutes";

export default function App() {
    const isAuthenticated = useIsAuthenticated();
    console.log("Is authenticated:", isAuthenticated);

    return (
        <div className="App">
            {isAuthenticated ? <AllRoutes /> : <SafeRoutes />}
        </div>
    );
}
